import axios from "axios";

const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";

export const encuestaApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  return {
    list: async (params) => {
      const { nombre = "", pagination = {} } = params;
      const { page = 1, pageSize = 50 } = pagination;
      const response = await axios.get(
        `${URL_BASE}/encuesta/list/?search=${nombre}&page=${page}&page_size=${pageSize}`,
        {
          headers,
        }
      );
      return response.data;
    },
    detail: async (id) => {
      const response = await axios.get(`${URL_BASE}/encuesta/${id}/detail`, {
        headers,
      });
      return response.data;
    },
    respuestas: async (id) => {
      const response = await axios.get(
        `${URL_BASE}/encuesta/${id}/respuestas/`,
        {
          headers,
        }
      );
      return response.data;
    },
    guardarRespuestas: async (data) => {
      const response = await axios.post(
        `${URL_BASE}/encuesta/guardar-respuestas/`,
        data,
        {
          headers,
        }
      );
      return response.data;
    },
  };
};
