import EncuestaColaborador from "../pages/EncuestaColaborador";
import EncuestasPage from "../pages/EncuestasPage";

export const encuestaRoutes = [
  {
    path: "/encuestas",
    element: <EncuestasPage />,
  },
  {
    path: "/encuestas/:asignacionId/",
    element: <EncuestaColaborador />,
  },
];
