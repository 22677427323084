import { createSlice } from "@reduxjs/toolkit";

export const encuestaDefault = {
  nombre: "",
  descripcion: "",
  fecha_inicio: null,
  fecha_fin: null,
  estado: false,
  preguntas: [
    {
      texto: "",
      descripcion: "",
      tipo_respuesta: "TEXTO",
      opciones: [],
    },
  ],
  alcance_total: null,
  empleados_ids: [],
};

export const asignacionRespuestasDefault = {
  asignacion_id: 0,
  respuestas: [],
};

const encuestaSlice = createSlice({
  name: "encuesta",
  initialState: {
    // Sección: Asignaciones
    asignaciones: [],
    asignaciones_loading: false,
    asignaciones_filter: {},
    asignaciones_count: 0,
    // Sección: Asignación
    asignacion: encuestaDefault,
    asignacion_loading: false,
    // Sección: Respuestas
    respuestas_asignacion: {},
    respuestas_asignacion_loading: false,
  },
  reducers: {
    // Sección: Asignaciones
    setAsignaciones: (state, { payload = {} }) => {
      state.asignaciones = payload;
      state.asignaciones_loading = false;
    },
    getAsignaciones: (state, { payload = {} }) => {
      state.asignaciones_loading = true;
    },
    setAsignacionesFilter: (state, { payload = {} }) => {
      state.asignaciones_filter = payload;
      state.asignaciones_loading = true;
    },
    setAsignacionesCount: (state, { payload = 0 }) => {
      state.asignaciones_count = payload;
    },
    // Sección: Asignación
    setAsignacion: (state, { payload = {} }) => {
      state.asignacion = payload;
      state.asignacion_loading = false;
    },
    getAsignacion: (state, { payload = {} }) => {
      state.asignacion_loading = true;
    },
    // Sección: Respuestas
    setRespuestasAsignacion: (state, { payload = {} }) => {
      state.respuestas_asignacion = payload;
      state.respuestas_asignacion_loading = false;
    },
    getRespuestasAsignacion: (state, { payload = {} }) => {
      state.respuestas_asignacion_loading = true;
    },
    guardarRespuestasAsignacion: (state, { payload = {} }) => {
      state.respuestas_asignacion = payload;
      state.respuestas_asignacion_loading = true;
    },
    setRespuestasAsignacionLoading: (state, { payload = false }) => {
      state.respuestas_asignacion_loading = payload;
    },
  },
});

export const encuestaActions = encuestaSlice.actions;
export const encuestaReducer = encuestaSlice.reducer;
