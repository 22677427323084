import React from "react";
import { useNavigate } from "react-router-dom";

export const renderEstadoEncuesta = (completado) => {
  const style = `inline-flex items-center rounded-md px-2 py-1 text-sm font-medium text-white ring-1 ring-inset ring-gray-500/10 ${
    completado ? "bg-green-500" : "bg-orange-500"
  }`;
  return (
    <span className={style}>{completado ? "Completado" : "Pendiente"}</span>
  );
};

export const RenderTableButton = ({ estado, completado, encuestaId }) => {
  const navigate = useNavigate();
  const style = `h-10 border border-black text-sm px-4 rounded`;

  return (
    <button
      className={style}
      onClick={() => {
        navigate(`/encuestas/${encuestaId}/`);
      }}
    >
      {completado
        ? "Ver respuestas"
        : estado
        ? "Realizar encuesta"
        : "Encuesta cerrada"}
    </button>
  );
};

export const validarRespuestas = (encuesta, asignacionRespuestas) => {
  // TODO: Validar respuestas
  return true;
};
