import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { getUserFromCookie } from "src/modules/common/handler/helpers";
import FormularioCalificaciones from "../components/FormularioCalificaciones";
import { evaluacionActions } from "../handlers/redux";
import {
  calcularPuntajeFormulario,
  ESTADOS_EVALUACION,
  ESTADOS_FORMULARIO,
  ETAPAS,
  ETAPA_POR_ESTADOS,
  getEstadoFormularioAnteriorOSiguiente,
  getEtapaAnteriorOSiguiente,
  modificarCalificacionHelper,
  modificarCalificacionKPIHelper,
  obtenerCalificacionesFormulario,
  obtenerCompetenciasCalificadasCopia,
  getPuntuacionesCustom,
} from "../helpers/helpers";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { colaboradorActions } from "src/modules/colaborador/handlers/redux";

function FormularioDevolucionPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { idEvaluacion, idFormulario } = params;
  const ETAPA = ETAPAS.DEVOLUCION;
  const usuario = getUserFromCookie();
  const [fueEditado, setFueEditado] = useState(false);
  const empresa = useSelector((state) => state.colaborador.empresa);
  const { habilitar_puntaje_evaluacion_mayor_100 = false } = empresa;
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const formulario = useSelector((state) => state.evaluacion.formulario);
  const formulario_loading = useSelector(
    (state) => state.evaluacion.formulario_loading
  );
  const modificarCalificacion = (
    competenciasKey,
    competencias,
    competenciaId,
    conductaId,
    calificacionId,
    campo, // calificacion o comentarios
    valor
  ) => {
    const newCompetencias = modificarCalificacionHelper(
      competenciasKey,
      competencias,
      competenciaId,
      conductaId,
      calificacionId,
      campo,
      valor
    );
    //
    if (newCompetencias.error) {
      ErrorToast({ message: newCompetencias.error });
      return;
    }
    //
    dispatch(
      evaluacionActions.setFormulario({
        ...formulario,
        ...newCompetencias,
      })
    );
    setFueEditado(true);
  };
  const modificarCalificacionKPI = (
    competenciasKey,
    competencias,
    competenciaId,
    campo,
    valor,
    valorSecundario // para calificacion/cumplimiento
  ) => {
    const newCompetencias = modificarCalificacionKPIHelper(
      competenciasKey,
      competencias,
      competenciaId,
      campo,
      valor,
      valorSecundario
    );
    //
    if (newCompetencias.error) {
      ErrorToast({ message: newCompetencias.error });
      return;
    }
    //
    dispatch(
      evaluacionActions.setFormulario({
        ...formulario,
        ...newCompetencias,
      })
    );
    setFueEditado(true);
  };
  const modificarComentario = (field) => (e) => {
    const comentario = e.target.value;
    dispatch(
      evaluacionActions.setFormulario({
        ...formulario,
        [field]: comentario,
      })
    );
    setFueEditado(true);
  };
  const onSubmit = () => {
    const {
      puntaje_obtenido,
      puntaje_devolucion,
      comentarios_evaluador,
      comentarios_calibrador,
      plan_accion,
    } = formulario;
    if (!plan_accion) {
      ErrorToast({
        message: "Se debe agregar un comentario para la devolución.",
      });
      return;
    }
    const calificaciones = obtenerCalificacionesFormulario(formulario, ETAPA);
    //
    const data = {
      puntaje_obtenido: puntaje_obtenido,
      puntaje_devolucion: puntaje_devolucion,
      comentarios_evaluador: comentarios_evaluador,
      comentarios_calibrador: comentarios_calibrador,
      plan_accion: plan_accion,
      ...calificaciones,
    };
    //
    dispatch(evaluacionActions.devolver(data));
  };
  const onSubmitParcial = () => {
    const {
      puntaje_obtenido,
      puntaje_devolucion,
      comentarios_evaluador,
      comentarios_calibrador,
      plan_accion,
    } = formulario;
    const calificaciones = obtenerCalificacionesFormulario(formulario, ETAPA);
    //
    const data = {
      puntaje_obtenido: puntaje_obtenido,
      puntaje_devolucion: puntaje_devolucion,
      comentarios_evaluador: comentarios_evaluador,
      comentarios_calibrador: comentarios_calibrador,
      plan_accion: plan_accion,
      es_parcial: true,
      ...calificaciones,
    };
    //
    dispatch(evaluacionActions.devolver(data));
  };
  const getInputComentarios = () => {
    const { tiene_calibracion_superior } = evaluacion;
    const { calibrador } = formulario;
    let inputComentarios = {
      comentarios_evaluador: { show: true, editable: false },
      comentarios_calibrador: {
        show: tiene_calibracion_superior && !!calibrador,
        editable: false,
      },
      plan_accion: { show: true, editable: true },
      comentarios_evaluado: { show: false, editable: false },
    };
    const { comentarios_calibrador } = formulario;
    //
    if (!comentarios_calibrador) {
      inputComentarios.comentarios_calibrador.show = false;
    }
    return inputComentarios;
  };
  const sePuedeEditar = () => {
    const { estado: estadoEvaluacion } = evaluacion;
    if (estadoEvaluacion?.value === ESTADOS_EVALUACION.FINALIZADO) {
      return false;
    }
    const estado = formulario.estado.value;
    const { nombre, apellido, colaborador_id } = formulario.evaluador;
    // const nombreEvaluador = `${nombre} ${apellido}`;
    const usuarioColaboradorId = Number(usuario?.colaborador_id);
    // const esUsuarioEvaluador = usuario?.nombre_completo === nombreEvaluador;
    const esUsuarioEvaluador = usuarioColaboradorId === colaborador_id;
    const { calibrador } = formulario;
    const etapaAnterior = getEstadoFormularioAnteriorOSiguiente(
      evaluacion,
      ETAPA,
      "anterior",
      formulario
    );
    let estadoHabilitado = ETAPA_POR_ESTADOS[etapaAnterior];
    if (!calibrador) {
      estadoHabilitado = ETAPA_POR_ESTADOS[ETAPAS.EVALUACION];
    }
    const estadoEditable =
      estado >= estadoHabilitado && estado <= ESTADOS_FORMULARIO.DEVUELTO;
    //
    return estadoEditable && esUsuarioEvaluador;
  };
  //
  useEffect(() => {
    dispatch(
      evaluacionActions.getFormulario({
        id: idEvaluacion,
        formularioId: idFormulario,
      })
    );
    //
    if (!empresa.id) {
      dispatch(colaboradorActions.getEmpresa());
    }
  }, []);
  useEffect(() => {
    if (Object.keys(formulario).length > 0) {
      let puntaje = calcularPuntajeFormulario(formulario, 3);
      puntaje = Number(Number(puntaje).toFixed(2));
      const puntajeFormulario = Number(
        Number(formulario.puntaje_obtenido).toFixed(2)
      );
      // const estadoAnterior = 3; //TODO: obtener estado anterior
      const estadoAnterior = getEstadoFormularioAnteriorOSiguiente(
        evaluacion,
        ESTADOS_FORMULARIO.DEVUELTO,
        "anterior",
        formulario
      );
      //
      if (!habilitar_puntaje_evaluacion_mayor_100) {
        if (puntaje > 100.0) {
          puntaje = 100;
        }
      }
      //
      if (puntajeFormulario !== puntaje) {
        dispatch(
          evaluacionActions.setFormulario({
            ...formulario,
            puntaje_devolucion: puntaje,
            puntaje_obtenido: puntaje,
          })
        );
      } else if (
        !formulario.puntaje_devolucion &&
        formulario.estado?.value === estadoAnterior &&
        !fueEditado
      ) {
        // De ser formulario nuevo duplicamos resultados del paso anterior
        // const etapaOrigen = 2; // TODO: obtener anterior
        const etapaOrigen = getEtapaAnteriorOSiguiente(
          evaluacion,
          ETAPA,
          "anterior",
          formulario
        );
        const etapaDestino = ETAPA;
        const competenciasDuplicadas = obtenerCompetenciasCalificadasCopia(
          formulario,
          etapaOrigen,
          etapaDestino
        );
        //
        dispatch(
          evaluacionActions.setFormulario({
            ...formulario,
            ...competenciasDuplicadas,
          })
        );
      }
    }
  }, [formulario]);
  //
  return (
    <div className="flex flex-col gap-4 mt-4 px-8">
      <div>
        <div className="border-b pb-3">
          <span className="font-bold text-sky-500 text-xl">Etapa:</span>{" "}
          <span className="text-xl">Devolución</span>
        </div>
      </div>
      {/*  */}
      {!formulario_loading && (
        <>
          {Object.keys(formulario).length > 0 && (
            <FormularioCalificaciones
              formulario={formulario}
              etapa={ETAPA}
              inputComentarios={getInputComentarios()}
              modificarCalificacionHandler={modificarCalificacion}
              modificarCalificacionKPIHandler={modificarCalificacionKPI}
              modificarComentario={modificarComentario}
              onSubmit={onSubmit}
              onSubmitParcial={onSubmitParcial}
              esEditable={sePuedeEditar()}
              submitButtonLabel={"Finalizar devolución"}
              puntuacionesCustom={getPuntuacionesCustom(evaluacion)}
              mostrarPesosIndicadores={
                evaluacion?.visualizar_pesos_indicadores_observacion
              }
            ></FormularioCalificaciones>
          )}
          {Object.keys(formulario).length < 1 && (
            <div className="flex justify-center border rounded-md p-2">
              No existe formulario
            </div>
          )}
        </>
      )}
      {!!formulario_loading && (
        <div className="flex justify-center p-2">
          <LoadingSpinButton
            message={"Cargando formulario, por favor espere"}
          ></LoadingSpinButton>
        </div>
      )}
    </div>
  );
}
export default FormularioDevolucionPage;
