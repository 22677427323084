import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "src/modules/app/components/Header";
import { Card } from "src/modules/common/components/Card";
import { encuestaActions } from "../handlers/redux";
import DataTable from "src/modules/common/components/DataTable";
import { renderEstadoEncuesta, RenderTableButton } from "../helpers/helper";
import dayjs from "dayjs";
import { ChevronRight } from "react-bootstrap-icons";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import SearchInput from "src/modules/common/components/SearchInput";
import { commonActions } from "src/modules/common/handler/redux";

const EncuestasPage = () => {
  const dispatch = useDispatch();

  const asignaciones = useSelector((state) => state.encuesta.asignaciones);
  const asignaciones_loading = useSelector(
    (state) => state.encuesta.asignaciones_loading
  );
  const asignaciones_count = useSelector(
    (state) => state.encuesta.asignaciones_count
  );
  const asignaciones_filter = useSelector(
    (state) => state.encuesta.asignaciones_filter
  );

  const { nombre = "" } = asignaciones_filter;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  useEffect(() => {
    dispatch(commonActions.setTitle("Encuestas"));
    dispatch(commonActions.showSidebar(true));
    dispatch(encuestaActions.getAsignaciones({}));
  }, []);

  const goToEncuesta = (item) => {
    console.log(item);
  };

  const datatableConfig = {
    headers: [
      {
        title: "Creadas",
        contentClass: "font-bold",
        // onOrder: setOrderingPermisos("empleado"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-row gap-2 items-center p-2">
            <h1 className="font-bold">{item.encuesta.nombre}</h1>
          </div>
        ),
      },
      {
        title: "Inicio",
        // accessor: "fecha_inicio",
        // onOrder: setOrderingPermisos("tipo_permiso"),
        contentRenderer: (item) => (
          <div>
            {item.encuesta.fecha_inicio
              ? dayjs(item.encuesta.fecha_inicio).format("DD/MM/YYYY HH:mm")
              : "Sin fecha"}
          </div>
        ),
      },
      {
        title: "Finalización",
        accessor: "fecha_fin",
        // onOrder: setOrderingPermisos("estado"),
        contentRenderer: (item) => (
          <div>
            {item.encuesta.fecha_fin
              ? dayjs(item.encuesta.fecha_fin).format("DD/MM/YYYY HH:mm")
              : "Sin fecha"}
          </div>
        ),
      },
      {
        title: "Estado",
        // accessor: "estado",
        // onOrder: setOrderingPermisos("estado"),
        contentRenderer: (item) => {
          return <div>{renderEstadoEncuesta(item.completado)}</div>;
        },
      },
      {
        title: "",
        // accessor: "estado",
        // onOrder: setOrderingPermisos("estado"),
        contentRenderer: (item) => {
          return (
            // @ts-ignore
            <RenderTableButton
              estado={item.encuesta.estado}
              completado={item.completado}
              encuestaId={item.id}
            />
          );
        },
      },
    ],
    rowActions: [],
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      encuestaActions.setAsignacionesFilter({
        ...asignaciones_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const onSearch = (e) => {
    const value = e.target.value;
    dispatch(
      encuestaActions.setAsignacionesFilter({
        ...asignaciones_filter,
        nombre: value,
      })
    );
  };

  return (
    <>
      <Header
        titulo={"Encuestas"}
        subtitulo={"Este es el resumen del perfil seleccionado"}
        className={"bg-white"}
      />
      <div className="px-12">
        <Card className="mt-4">
          <h3 className="font-semibold text-lg mt-4 border-b pb-2">
            Listado de encuestas
          </h3>
          <div className="flex w-1/2 mt-4">
            <SearchInput
              containerClass={``}
              placeholder={"Buscar por nombre..."}
              value={nombre}
              onChange={onSearch}
            />
          </div>
          <DataTable
            containerClass={"my-4"}
            data={asignaciones}
            loading={asignaciones_loading}
            config={datatableConfig}
            // Pagination
            totalRecords={asignaciones_count}
            onPaginate={onPaginate}
            outerPageSize={pageSize}
            //
            checkable={false}
          />
        </Card>
      </div>
    </>
  );
};

export default EncuestasPage;
