import { all } from "redux-saga/effects";
import { authSagas } from "src/modules/auth/handlers/sagas";
import { colaboradorSagas } from "src/modules/colaborador/handlers/sagas";
import { equipoSagas } from "src/modules/equipo/handlers/sagas";
import { evaluacionSagas } from "src/modules/evaluacion/handlers/sagas";
import { kpiSagas } from "src/modules/kpi/handlers/sagas";
import { inicioSagas } from "../modules/inicio/handlers/sagas";
import { planCarreraSagas } from "src/modules/plan_carrera/handlers/sagas";
import { objetivosCargoSagas } from "src/modules/objetivo_cargo/handlers/sagas";
import { beneficiosSagas } from "src/modules/beneficio/handlers/sagas";
import { feedbackSagas } from "src/modules/feedback/handlers/sagas";
import { permisoSagas } from "src/modules/permiso/handlers/sagas";
import { encuestasSagas } from "src/modules/encuesta/handlers/sagas";

export function* rootSagas() {
  yield all([
    authSagas(),
    inicioSagas(),
    equipoSagas(),
    colaboradorSagas(),
    kpiSagas(),
    evaluacionSagas(),
    planCarreraSagas(),
    objetivosCargoSagas(),
    beneficiosSagas(),
    feedbackSagas(),
    permisoSagas(),
    encuestasSagas(),
  ]);
}
