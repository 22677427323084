import React from "react";

const EtiquetaComponent = (props) => {
  const { key, name, color } = props;

  let textColor = "";
  // Si la etiqueta es blanca se muestra el texto en negro, si no se muestra en blanco
  if (color === "#FFFFFF" || color === "#ffffff") {
    textColor = "text-black";
  } else {
    textColor = "text-white";
  }

  return (
    <>
      <div
        key={key}
        className={`border rounded-md px-1 ${textColor}`}
        style={{ backgroundColor: color }}
        title={name}
      >
        {name}
      </div>
    </>
  );
};

export default EtiquetaComponent;
