import axios from "axios";

const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";

export const colaboradorApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    list: async ({ colaboradores_filter }) => {
      const {
        nombre = "",
        areaId = "",
        sucursalId = "",
        departamentoId = "",
      } = colaboradores_filter;
      try {
        const colaboradores = await axios.get(
          `${URL_BASE}/colaborador/?search=${nombre}&area_id=${areaId}&sucursal_id=${sucursalId}&departamento_id=${departamentoId}`,
          {
            headers,
          }
        );
        return colaboradores.data;
      } catch (error) {
        console.log("areas error", error);
        throw error;
      }
    },
    nuevosIngresos: async () => {
      try {
        const colaboradores = await axios.get(
          `${URL_BASE}/colaborador/nuevos-ingresos/`,
          {
            headers,
          }
        );
        return colaboradores.data;
      } catch (error) {
        console.log("areas error", error);
        throw error;
      }
    },
    colaborador: async ({ id }) => {
      try {
        const colaborador = await axios.get(`${URL_BASE}/colaborador/${id}/`, {
          headers,
        });
        return colaborador.data;
      } catch (error) {
        console.log("colaborador error", error);
        throw error;
      }
    },
    update: async ({ id, foto_empleado }) => {
      const headersForm = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      //
      try {
        const colaborador = await axios.put(
          `${URL_BASE}/colaborador/${id}/update`,
          { foto_empleado: foto_empleado },
          {
            headers: headersForm,
          }
        );
        return colaborador.data;
      } catch (error) {
        console.log("colaborador error", error);
        throw error;
      }
    },
    //
    areas: async () => {
      try {
        const areas = await axios.get(`${URL_BASE}/colaborador/area/`, {
          headers,
        });
        return areas.data;
      } catch (error) {
        console.log("areas error", error);
        throw error;
      }
    },
    sucursales: async () => {
      try {
        const sucursales = await axios.get(
          `${URL_BASE}/colaborador/sucursal/`,
          {
            headers,
          }
        );
        return sucursales.data;
      } catch (error) {
        console.log("areas error", error);
        throw error;
      }
    },
    departamentos: async () => {
      try {
        const departamentos = await axios.get(
          `${URL_BASE}/colaborador/departamento/`,
          {
            headers,
          }
        );
        return departamentos.data;
      } catch (error) {
        console.log("areas error", error);
        throw error;
      }
    },
    //
    getEvaluaciones: async ({ id, colaborador_evaluaciones_filter }) => {
      const {
        nombre = "",
        ordering = "",
        anho_finalizacion,
      } = colaborador_evaluaciones_filter;
      let searchOrdering = `?search=${nombre}`;
      if (ordering) {
        searchOrdering += `&ordering=${ordering}`;
      }
      if (anho_finalizacion) {
        searchOrdering += `&anho_finalizacion=${anho_finalizacion}`;
      }
      //
      try {
        const response = await axios.get(
          `${URL_BASE}/colaborador/${id}/evaluaciones${searchOrdering}`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        console.log("getEvaluaciones api error", error);
        throw error;
      }
    },
    //
    getEmpresa: async () => {
      try {
        let response = await axios.get(`${URL_BASE}/colaborador/empresa/`, {
          headers,
        });
        return response.data;
      } catch (error) {
        console.log("getEmpresas api error", error);
        throw error;
      }
    },
  };
};
