import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getToken } from "src/modules/common/handler/helpers";
import { encuestaApi } from "./apis";
import { asignacionRespuestasDefault, encuestaActions } from "./redux";
import { push } from "@lagunovsky/redux-react-router";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

function* encuestasListSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApi(token).list, payload);
    yield put(encuestaActions.setAsignaciones(data.results));
    yield put(encuestaActions.setAsignacionesCount(data.count));
  } catch (error) {
    yield put(encuestaActions.setAsignaciones([]));
    yield put(encuestaActions.setAsignacionesCount(0));
  }
}

function* encuestaDetailSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApi(token).detail, payload);
    yield put(encuestaActions.setAsignacion(data));
  } catch (error) {
    yield put(encuestaActions.setAsignacion({}));
  }
}

function* respuestasAsignacionSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApi(token).respuestas, payload);
    yield put(encuestaActions.setRespuestasAsignacion(data));
  } catch (error) {
    yield put(
      encuestaActions.setRespuestasAsignacion(asignacionRespuestasDefault)
    );
  }
}

function* guardarRespuestasAsignacionSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApi(token).guardarRespuestas, payload);
    SuccessToast({ message: "Respuestas guardadas exitosamente." });
    yield put(push("/encuestas"));
    yield put(encuestaActions.setRespuestasAsignacionLoading(false));
  } catch (error) {
    ErrorToast({ message: "Ocurrio un error al guardar las respuestas." });
    yield put(encuestaActions.setRespuestasAsignacionLoading(false));
  }
}

export function* encuestasSagas() {
  // Sección: Asignaciones
  yield takeEvery(encuestaActions.getAsignaciones.type, encuestasListSaga);
  yield takeLatest(
    encuestaActions.setAsignacionesFilter.type,
    encuestasListSaga
  );
  // Sección: Asignacion
  yield takeEvery(encuestaActions.getAsignacion.type, encuestaDetailSaga);
  // Sección: Respuestas
  yield takeEvery(
    encuestaActions.getRespuestasAsignacion.type,
    respuestasAsignacionSaga
  );
  yield takeEvery(
    encuestaActions.guardarRespuestasAsignacion.type,
    guardarRespuestasAsignacionSaga
  );
}
